import { Box, Container, Paper, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { INews } from '../../storeSlices/newsSlice/contracts.news';
import formatDate from '../../utils/formatDate';

import { ArrowSvg } from '../svgIcons';
import { cutStringBySymbols } from '../../utils/cutString';

import './index.scss';

const Slide = ({ title, createdAt, content, backgroundImage, id }: INews) => {
  const navigate = useNavigate();
  return (
    <Paper
      sx={{
        backgroundImage: `url(${backgroundImage?.url})`,
        width: '100vw',
        height: '600px',
        display: 'flex',
        alignItems: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover, contain',
      }}
    >
      <Container
        disableGutters
        sx={{
          color: '#000',
        }}
      >
        <Box sx={{ mb: '4rem', ml: '2rem' }}>
          <Typography component="h1" variant="h3" className="title">
            {title}
          </Typography>
          <Typography component="h2" variant="h4" className="subtitle">
            {formatDate(createdAt)}
          </Typography>
        </Box>
        <Box
          sx={{
            ml: '2rem',
          }}
        >
          <Typography component="p" variant="h5" className="text" sx={{ mb: '1rem' }}>
            {cutStringBySymbols(content)}
          </Typography>
          <Box
            className="slide-addition"
            sx={{
              display: 'flex',
              alignItems: 'center',
              cursor: 'pointer',
              maxWidth: '200px',
            }}
            onClick={() => navigate(`/newsPage/${id}`, { replace: true })}
          >
            <Typography component="p" variant="h6" sx={{ mr: '1rem' }} className="text">
              Подробнее...
            </Typography>
            <ArrowSvg />
          </Box>
        </Box>
      </Container>
    </Paper>
  );
};

export default Slide;
