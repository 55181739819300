import { Box } from '@mui/material';

import config from '../../config/config';
import { PhoneSvg, UserSvg } from '../svgIcons';

const HamburgerNavIcons = () => {
  return (
    <Box className="hamburger-menu__icons-wrapper" sx={{ mr: '1rem', display: 'flex' }}>
      <PhoneSvg width="20px" height="20px" />
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        <a href={config.profileLink}>
          <UserSvg width="20px" height="20px" />
        </a>
      </Box>
    </Box>
  );
};

export default HamburgerNavIcons;
