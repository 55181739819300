import { createAsyncThunk } from '@reduxjs/toolkit';
import { createNews } from '../../../api/news/newsApi';
import { CreateNewsDto } from '../../../view/adminka/news/main/contracts.news';

const createNewsThunk = createAsyncThunk(
  'news/createNewsThunk',
  async (createNewsDto: CreateNewsDto) => {
    const news = await createNews(createNewsDto);
    return news;
  }
);

export default createNewsThunk;
