import { getNewsFormDefaultValues } from '../utils';
import { useAppDispatch, useAppSelector } from '../../../../../hooks';

import { newsSuccessfullyUpdated } from '../../../../../storeSlices/utils/successMessages.constants';
import { useUpdateNewsMutation } from '../../../../../storeSlices/newsSlice/query/newsApiSlice';
import { selectedOneNews, updateNews } from '../../../../../storeSlices/newsSlice/newsStoreSlice';
import NewsFormPresent from '../view/NewsFormPresent';
import { useParams } from 'react-router';
import { useCallback } from 'react';
import useEnqueueMsgWithData from '../../../../../hooks/useEnqueueMsg/useEnqueueMsgWithData';
import { NewsFormData } from '../newsForm.contracts';
import { baseAxios } from '../../../../../api/axios/axios';

export default function NewsFormUpdate() {
  const { id } = useParams();
  const title = 'Редактирование  новости';
  const [updateNewsMutation] = useUpdateNewsMutation();

  const dispath = useAppDispatch();

  const news = useAppSelector(selectedOneNews(id ?? ''));
  const defaultValues = getNewsFormDefaultValues(news);

  const updNews = useCallback(
    async (data: NewsFormData) => {
      const dto = {
        title: data.title,
        content: data.content,
      };

      if (id) {
        const news = await updateNewsMutation({ dto, id }).unwrap();
        const multi = new FormData();
        data.files.forEach((file) => multi.append('file', file));
        const res = await baseAxios.patch(`/upload-file/news/${news.id}`, multi);
        dispath(updateNews({ ...news, backgroundImage: res.data }));
      }
    },
    [dispath, id, updateNewsMutation]
  );

  const onSubmit = useEnqueueMsgWithData({
    fn: updNews,
    msg: newsSuccessfullyUpdated,
  });

  return <NewsFormPresent defaultValues={defaultValues} onSubmit={onSubmit} title={title} />;
}
