import { createAsyncThunk } from '@reduxjs/toolkit';
import { UpdateNewsDto } from '../../../view/adminka/news/main/contracts.news';
import { INews } from '../contracts.news';
import { updateNews } from '../../../api/news/newsApi';

const updateNewsThunk = createAsyncThunk(
  'news/updateNewsThunk',
  async ({ updateNewsDto, news }: { updateNewsDto: UpdateNewsDto; news: INews }) => {
    const updatedNews = await updateNews(news.id, updateNewsDto);
    return updatedNews;
  }
);

export default updateNewsThunk;
