import { Box, Typography } from '@mui/material';

import { UserSvg } from '../svgIcons';
import { useAdmin } from '../../hooks';
import config from '../../config/config';
import { Link } from 'react-router-dom';

export const HeaderLinks = () => {
  const isAdmin = useAdmin();
  return (
    <>
      {isAdmin && (
        <Typography component="p" variant="body1" sx={{ mr: '1.5rem' }}>
          <Link to={'/adminka'}>АДМИНКА</Link>
        </Typography>
      )}
      <a href={config.profileLink}>
        <Box sx={{ display: 'flex', alignItems: 'center', gap: '0.85rem' }}>
          <Typography component="p" variant="body1">
            Личный кабинет
          </Typography>
          <UserSvg width="20px" height="20px" />
        </Box>
      </a>
    </>
  );
};
