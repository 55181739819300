import { getNewsFormDefaultValues } from '../utils';
import { useAppDispatch } from '../../../../../hooks';

import { newsSuccessfullyCreated } from '../../../../../storeSlices/utils/successMessages.constants';
import { useCreateNewsMutation } from '../../../../../storeSlices/newsSlice/query/newsApiSlice';
import { addNews } from '../../../../../storeSlices/newsSlice/newsStoreSlice';
import NewsFormPresent from '../view/NewsFormPresent';
import { useCallback } from 'react';
import useEnqueueMsgWithData from '../../../../../hooks/useEnqueueMsg/useEnqueueMsgWithData';
import { NewsFormData } from '../newsForm.contracts';
import { baseAxios } from '../../../../../api/axios/axios';

export default function NewsFormCreate() {
  const title = 'Создание новости';
  const [createNewsMutation] = useCreateNewsMutation();

  const dispath = useAppDispatch();

  const defaultValues = getNewsFormDefaultValues();

  const createNews = useCallback(
    async ({ title, content, files }: NewsFormData) => {
      const news = await createNewsMutation({ title, content }).unwrap();
      const multi = new FormData();
      files.forEach((file) => multi.append('file', file));
      const res = await baseAxios.post(`/upload-file/news/${news.id}`, multi);
      dispath(addNews({ ...news, backgroundImage: res.data }));
    },
    [createNewsMutation, dispath]
  );

  const onSubmit = useEnqueueMsgWithData({
    fn: createNews,
    msg: newsSuccessfullyCreated,
  });

  return <NewsFormPresent defaultValues={defaultValues} onSubmit={onSubmit} title={title} />;
}
