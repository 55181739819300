import axios, { InternalAxiosRequestConfig } from 'axios';
import config from '../../config/config';
import { fakeAxios } from '../../utils/fakeAxios';

const baseAxios = axios.create({ baseURL: config.apiBaseUrl });

export const privateAxios = axios.create({
  baseURL: config.apiBaseUrl,
  headers: { 'Content-Type': 'application/json' },
  withCredentials: false,
});

function requestInterceptor(req: InternalAxiosRequestConfig) {
  const tokensString = localStorage.getItem('tokens');
  if (!tokensString) return req;
  const tokens = JSON.parse(tokensString) as { accessToken: string; refreshToken: string };

  req.headers.Authorization = 'Bearer ' + tokens.accessToken;

  return req;
}

baseAxios.interceptors.request.use(requestInterceptor);

export { baseAxios };

// export  { fakeAxios as baseAxios }
